<template>
    <div id="addContractOrder">
        <div class="headBox">
            <el-button
                type="primary"
                icon="el-icon-caret-left"
                plain
                size="mini"
                @click="$router.push({ name: 'contractOrder' })"
                >返回</el-button
            >
            <div class="title">{{ dialogTitle }}</div>
        </div>
        <el-form ref="form" :model="form" :rules="rules" label-width="120px" class="form">
            <el-card class="box-card" shadow="hover">
                <div slot="header" class="clearfix">
                    <div class="header_tit">客户信息</div>
                    <i class="el-icon-paperclip"></i>
                </div>
                <el-form-item label="客户姓名" prop="customerName">
                    <el-input
                        v-model="form.customerName"
                        size="mini"
                        style="width: 320px"
                        @blur="jianyan('customerName')"
                    ></el-input>
                    <i class="el-icon-success status" style="color: #67c23a" v-if="rules.iscustomerName"></i>
                    <i
                        class="el-icon-error status"
                        style="color: #f56c6c"
                        v-else-if="rules.iscustomerName == false"
                    ></i>
                </el-form-item>
                <el-form-item label="身份证号码" prop="idCard">
                    <el-input v-model="form.idCard" size="mini" @blur="jianyan('idCard')"></el-input>
                    <i class="el-icon-success status" style="color: #67c23a" v-if="rules.isidCard"></i>
                    <i class="el-icon-error status" style="color: #f56c6c" v-else-if="rules.isidCard == false"></i>
                </el-form-item>
                <el-form-item label="证件照">
                    <el-upload
                        class="avatar-uploader"
                        action="null"
                        :show-file-list="false"
                        :on-success="fronthandleAvatarSuccess"
                        :before-upload="frontbeforeAvatarUpload"
                    >
                        <img v-if="frontImageUrl" :src="frontImageUrl" class="avatar" />
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                    <el-upload
                        class="avatar-uploader"
                        action="null"
                        :show-file-list="false"
                        :on-success="backhandleAvatarSuccess"
                        :before-upload="backbeforeAvatarUpload"
                    >
                        <img v-if="backImageUrl" :src="backImageUrl" class="avatar" />
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        <div class="el-upload__tip" slot="tip">只能上传图片，且不超过5MB</div>
                    </el-upload>
                </el-form-item>
            </el-card>
            <el-card class="box-card" shadow="hover">
                <div slot="header" class="clearfix">
                    <div class="header_tit">签约信息</div>
                    <i class="el-icon-paperclip"></i>
                </div>
                <el-form-item label="签约银行" prop="signingBank">
                    <el-input v-model="form.signingBank" size="mini" @blur="jianyan('signingBank')"></el-input>
                    <i class="el-icon-success status" style="color: #67c23a" v-if="rules.issigningBank"></i>
                    <i class="el-icon-error status" style="color: #f56c6c" v-else-if="rules.issigningBank == false"></i>
                </el-form-item>
                <el-form-item label="银行卡号" prop="bankCardNumber">
                    <el-input v-model="form.bankCardNumber" size="mini" @blur="jianyan('bankCardNumber')"></el-input>
                    <i class="el-icon-success status" style="color: #67c23a" v-if="rules.isbankCardNumber"></i>
                    <i
                        class="el-icon-error status"
                        style="color: #f56c6c"
                        v-else-if="rules.isbankCardNumber == false"
                    ></i>
                </el-form-item>
                <el-form-item label="银行预留手机号" prop="mobileNumber">
                    <el-input v-model="form.mobileNumber" size="mini" @blur="jianyan('mobileNumber')"></el-input>
                    <i class="el-icon-success status" style="color: #67c23a" v-if="rules.ismobileNumber"></i>
                    <i
                        class="el-icon-error status"
                        style="color: #f56c6c"
                        v-else-if="rules.ismobileNumber == false"
                    ></i>
                </el-form-item>
                <el-form-item label="签约金额" prop="signingAmount">
                    <el-input
                        v-model="form.signingAmount"
                        size="mini"
                        @input="handleInput"
                        @blur="jianyan('signingAmount')"
                    ></el-input>
                    元
                    <i class="el-icon-success status" style="color: #67c23a" v-if="rules.issigningAmount"></i>
                    <i
                        class="el-icon-error status"
                        style="color: #f56c6c"
                        v-else-if="rules.issigningAmount == false"
                    ></i>
                </el-form-item>
                <el-form-item label="是否分期">
                    <el-switch v-model="form.isStages" active-color="#13ce66" inactive-color="#ccc"></el-switch>
                </el-form-item>
                <el-form-item label="分期数" v-if="form.isStages" prop="stagesNumber">
                    <el-input v-model="form.stagesNumber" size="mini" @blur="jianyan('stagesNumber')"></el-input>
                    <i class="el-icon-success status" style="color: #67c23a" v-if="rules.isstagesNumber"></i>
                    <i
                        class="el-icon-error status"
                        style="color: #f56c6c"
                        v-else-if="rules.isstagesNumber == false"
                    ></i>
                </el-form-item>
            </el-card>
        </el-form>
        <span class="footer">
            <el-button type="primary" plain @click="add('form')" :loading="saveLoading">保存</el-button>
        </span>
    </div>
</template>
<script>
import contractOrderApi from "../../api/contractOrderApi";
import axios from "axios";
export default {
    name: "addContractOrder",
    components: {},
    data() {
        return {
            form: {},
            rules: {
                customerName: [{ required: true, message: "未输入客户名称", trigger: "blur" }],
                iscustomerName: null,
                mobileNumber: [
                    { required: true, message: "未输入银行预留手机号码", trigger: "blur" },
                    { validator: this.validateMobile, trigger: "blur" }
                ],
                ismobileNumber: null,

                idCard: [
                    { required: true, message: "未输入身份证号码", trigger: "blur" },
                    { validator: this.validateIdNumber, trigger: "blur" }
                ],
                isidCard: null,
                signingBank: [{ required: true, message: "未输入签约银行", trigger: "blur" }],
                issigningBank: null,
                bankCardNumber: [
                    { required: true, message: "未输入银行卡号", trigger: "blur" },
                    { validator: this.validateBankCardNumber, trigger: "blur" }
                ],
                isbankCardNumber: null,
                signingAmount: [{ required: true, message: "未输入签约金额", trigger: "blur" }],
                issigningAmount: null,
                stagesNumber: [
                    { required: true, message: "未输入分期数", trigger: "blur" },
                    { validator: this.validateStagesNumber, trigger: "blur" }
                ],
                isstagesNumber: null
            },
            backImageUrl: "",
            frontImageUrl: "",
            saveLoading: false,
            dialogTitle: "创建签约订单",
            editId: null
        };
    },
    mounted() {
        if (this.$route.query.id) {
            this.editId = this.$route.query.id;
            this.dialogTitle = "编辑签约订单";
            this.$ajax.get("//safepayment.com.cn:18080/contractOrders/get/" + this.editId).then(res => {
                if ((res.status = 200 && res.data != null)) {
                    this.$set(res.data, "isStages", res.data.signingType == 1 ? true : false);
                    res.data.stagesNumber = res.data.stagesNumber == 0 ? "" : res.data.stagesNumber;
                    this.form = res.data;
                    this.frontImageUrl = "//safepayment.com.cn:18080/attachment" + res.data.idCardFrontImagePath;
                    this.backImageUrl = "//safepayment.com.cn:18080/attachment" + res.data.idCardBackImagePath;
                }
            });
        } else {
            this.dialogTitle = "创建签约订单";
        }
    },
    methods: {
        add(formName) {
            this.$refs[formName].validate(valid => {
                if (valid) {
                    this.saveLoading = true;
                    this.form.settlementType = "T1";
                    this.form.signingType = this.form.isStages ? 1 : 0;
                    if (this.dialogTitle == "创建签约订单") {
                        contractOrderApi
                            .addOrder(this.form)
                            .then(res => {
                                if (res.status == 200) {
                                    this.$message({
                                        type: "success",
                                        message: "创建成功"
                                    });
                                    this.$router.push({ name: "contractOrder" });
                                } else {
                                    this.$message({
                                        type: "warning",
                                        message: "创建失败"
                                    });
                                }
                                this.saveLoading = false;
                            })
                            .catch(error => {
                                this.$message({
                                    type: "warning",
                                    message: "只能上传图片，且不超过5MB"
                                });
                            });
                    } else {
                        let formData = new FormData();
                        for (let key in this.form) {
                            formData.append(key, this.form[key]);
                        }
                        axios
                            .put("//safepayment.com.cn:18080/contractOrders/update", formData, {
                                headers: {
                                    "Content-Type": "multipart/form-data"
                                }
                            })
                            .then(res => {
                                if (res.status == 200) {
                                    this.$message({
                                        type: "success",
                                        message: "修改成功"
                                    });
                                    this.$router.push({ name: "contractOrder" });
                                } else {
                                    this.$message({
                                        type: "warning",
                                        message: "修改失败"
                                    });
                                }
                            })
                            .catch(error => {
                                this.$message({
                                    type: "warning",
                                    message: "只能上传图片，且不超过5MB"
                                });
                            });
                    }
                } else {
                    return false;
                }
            });
        },
        validateMobile(rule, value, callback) {
            const reg = /^1[3456789]\d{9}$/;
            if (value === "") {
                callback(new Error("未输入银行预留手机号码"));
            } else if (!reg.test(value)) {
                callback(new Error("未输入正确的手机号码"));
            } else {
                callback();
            }
        },
        validateIdNumber(rule, value, callback) {
            if (!value) {
                callback(new Error("未输入身份证号"));
            } else {
                const reg = /^\w{18}$/;
                if (!reg.test(value)) {
                    callback(new Error("未输入正确的身份证号码"));
                } else {
                    callback();
                }
            }
        },
        validateBankCardNumber(rule, value, callback) {
            if (!value) {
                callback(new Error("未输入银行卡号"));
            } else {
                const reg = /^\d{16,19}$/;
                if (!reg.test(value)) {
                    callback(new Error("未输入正确的银行卡号"));
                } else {
                    callback();
                }
            }
        },
        validateStagesNumber(rule, value, callback) {
            if (value === "") {
                callback(new Error("未输入分期数"));
            } else {
                const reg = /^[0-9]*$/;
                if (!reg.test(value)) {
                    callback(new Error("未输入整数"));
                } else {
                    callback();
                }
            }
        },
        fronthandleAvatarSuccess(res, file) {
            this.frontImageUrl = URL.createObjectURL(file.raw);
        },
        backhandleAvatarSuccess(res, file) {
            this.backImageUrl = URL.createObjectURL(file.raw);
        },
        backbeforeAvatarUpload(file) {
            this.form.idCardBackImage = file;
            this.backImageUrl = URL.createObjectURL(file);
            if (this.form.idCardBackImagePath != "") {
                this.$delete(this.form, "idCardBackImagePath");
            }
            return false;
        },
        frontbeforeAvatarUpload(file) {
            this.form.idCardFrontImage = file;
            this.frontImageUrl = URL.createObjectURL(file);
            if (this.form.idCardFrontImagePath != "") {
                this.$delete(this.form, "idCardFrontImagePath");
            }
            return false;
        },
        handleInput(value) {
            const cleanValue = value.replace(/[^0-9.]/g, "");
            const validValue = cleanValue.split(".").reduce((acc, part, index, array) => {
                if (index === 0) {
                    return acc + part;
                } else if (index === 1 && array[0] !== "") {
                    return acc + "." + part;
                }
                return acc;
            }, "");
            this.form.signingAmount = validValue;
        },

        jianyan(prop) {
            this.$refs.form.validateField(prop, errorMessage => {
                if (!errorMessage) {
                    this.rules["is" + prop] = true;
                } else {
                    this.rules["is" + prop] = false;
                }
            });
        }
    }
};
</script>
<style>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 120px;
    height: 120px;
    line-height: 120px;
    text-align: center;
}
.avatar {
    width: 120px;
    height: 120px;
    display: block;
}
</style>
<style lang="scss" scoped>
#addContractOrder {
    background: #ffffff;
    padding: 20px;
    border-radius: 4px;
    margin-top: 20px;
    margin-bottom: 20px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    overflow: auto;
    padding-left: 17%;
    .title {
        font-size: 20px;
        font-weight: 700;
        margin: 0 auto;
        padding-right: 100px;
    }
    .headBox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 40px;
        width: 80%;
        h3 {
            font-size: 18px;
            font-weight: 500;
            color: #222222;
            line-height: 1;
        }
        .right {
            display: flex;
            align-items: center;
        }
    }
    .header_tit {
        font-weight: 700;
        font-size: 16px;
        padding-left: 10px;
    }
    .infoBox {
        .infoItem {
            border-bottom: 1px solid #eee;
            padding: 0 10px 4px 10px;
            margin: 0 10px 20px;
            display: flex;
            justify-content: space-between;
            &:last-child {
                border: 0;
            }
            .tit {
                font-weight: 700;
            }
            .zjz {
                margin-left: 50px;
                margin-top: 5px;
                cursor: pointer;
                display: flex;
                gap: 10px;
                .img {
                    width: 120px;
                    height: 120px;
                    border: 1px dashed #d9d9d9;
                    margin-top: 15px;
                    &:first-child {
                        margin-bottom: 15px;
                    }
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
        .zjzInfo {
            display: block;
            margin: 0 10px 15px;
        }
    }

    .form {
        /deep/ .el-input {
            width: 320px;
        }
        .el-form-item {
            padding: 0 20px 0 30px;
            margin-bottom: 15px;
            .status {
                margin: 5px 0 0 10px;
            }
        }
        .el-upload__tip {
            color: #e6a23c;
        }
    }
    .box-card {
        margin-bottom: 30px;
        width: 80%;
        /deep/.el-card__header {
            background: #f4f4f5;
        }
        .clearfix {
            display: flex;
            justify-content: space-between;
            align-items: center;
            i {
                font-size: 16px;
                font-weight: 600;
            }
        }
    }

    .footer {
        display: flex;
        justify-content: center;
        width: 80%;
    }
}
</style>

