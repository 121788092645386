import utilsApi from '../assets/js/utils'

import ajax from '../assets/js/axios.config'

export default utilsApi.creatApi({
   getPageList:['post', '/contractOrders/page'], 
   getOrder:['get', '/contractOrders/get'], 
   addOrder:['postFrom', '/contractOrders/add'], 
   updateOrder:['put', '/contractOrders/update'], 
   delOrder:['post', '/contractOrders/delete'], 
   
   

})